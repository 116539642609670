import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "currentColor",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  d: "M13 8C12.4477 8 12 7.55228 12 7C12 6.44772 12.4477 6 13 6H20.9998C20.9999 6 20.9999 6 21 6C21.1356 6 21.2649 6.02699 21.3828 6.07588C21.5007 6.12468 21.6112 6.19702 21.7071 6.29289C21.803 6.38877 21.8753 6.49927 21.9241 6.61722C21.9685 6.72447 21.9934 6.83787 21.9989 6.95203C21.9995 6.96494 21.9998 6.97785 22 6.99077C22 6.99385 22 6.99692 22 7C22 7.00033 22 7.00065 22 7.00098V15C22 15.5523 21.5523 16 21 16C20.4477 16 20 15.5523 20 15V9.41421L13.7071 15.7071C13.3166 16.0976 12.6834 16.0976 12.2929 15.7071L9 12.4142L3.70711 17.7071C3.31658 18.0976 2.68342 18.0976 2.29289 17.7071C1.90237 17.3166 1.90237 16.6834 2.29289 16.2929L8.29289 10.2929C8.68342 9.90237 9.31658 9.90237 9.70711 10.2929L13 13.5858L18.5858 8H13Z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}